import { ProbeArgument } from '../createStore';
import {
  clearSessionStorage,
  saveStateToSessionStorage,
  initApp,
  setIsUserLoggedIn,
  setSavedAddresses,
  userLoggedIn,
  setIsLoadingAddressesFromServer,
  saveAddressToServer,
} from './session.actions';
import { SESSION_STORAGE_KEY } from '../../core/constants';
import moment from 'moment-timezone';
import { AddressesWeb, ListResponse } from '@wix/ambassador-addresses-web/http';
import { setDeliveryAddress } from '../checkout/checkout.actions';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { Dispatch } from 'redux';
import { Action, Address as OloAddress } from '@wix/restaurants-client-logic';
import WixInstance from '@wix/wixrest-utils/dist/WixInstance';
import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import { SaveAddressToServerPayload } from './session.actions.types';

export default function sessionProbe({ onAction, onActionOnce }: ProbeArgument) {
  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    if (flowAPI.biLogger) {
      flowAPI.biLogger.initSession({ projectName: undefined } as any);
    }
    dispatch(
      setIsUserLoggedIn({ isLoggedIn: flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor' }),
    );

    const { isMembersAddressEnabled, shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch);
    }

    if (isMembersAddressEnabled && !flowAPI.environment.isSSR) {
      flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => dispatch(userLoggedIn()));
    }
  });

  onActionOnce(userLoggedIn.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(setIsUserLoggedIn({ isLoggedIn: true }));

    const { shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch);
    }
  });

  onAction(saveStateToSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    const { cart, checkout, addressForm } = getState();
    const { orderItems, coupon, comment } = cart;
    const { checkoutStep, contact } = checkout;
    const { selectedAddressOption } = addressForm;
    const timestamp = moment().valueOf();

    flowAPI.controllerConfig.platformAPIs.storage.session.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        orderItems,
        coupon,
        comment,
        dispatch: checkout.dispatch,
        checkoutStep,
        contact,
        selectedAddressOption,
        timestamp,
      }),
    );
  });

  onAction(clearSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    flowAPI.controllerConfig.platformAPIs.storage.session.removeItem(SESSION_STORAGE_KEY);
  });

  onAction(
    saveAddressToServer.toString(),
    async (action: Action<SaveAddressToServerPayload>, getState, dispatch, { flowAPI }) => {
      const signedInstance = getSignedInstance(flowAPI);
      const headers = { Authorization: signedInstance };
      const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
      addressesService.create({ address: action.payload.address, setAsDefault: false });
    },
  );
}

async function checkMembersAreaPrerequisits(flowAPI: ControllerFlowAPI) {
  const experiments = await flowAPI.getExperiments();
  const isMembersAddressEnabled = experiments.enabled('specs.restaurants.olo-client-members-area-addresses');
  const isUserLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';

  return {
    isMembersAddressEnabled,
    isUserLoggedIn,
    shouldInitMemberAddresses: isMembersAddressEnabled && isUserLoggedIn,
  };
}

function getSignedInstance(flowAPI: ControllerFlowAPI) {
  return (
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(WixInstance.ORDERS_APP_ID) ||
    flowAPI.controllerConfig.appParams.instance
  );
}

async function initMemberAddresses(flowAPI: ControllerFlowAPI, dispatch: Dispatch<Action<any>>) {
  dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));
  const signedInstance = getSignedInstance(flowAPI);
  const headers = { Authorization: signedInstance };
  const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
  const { addresses, defaultAddressId }: ListResponse = await addressesService.list({});
  dispatch(setSavedAddresses({ addresses, defaultAddressId }));
  dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));

  const defaultAddress = addresses.find((address) => address.id === defaultAddressId);
  if (defaultAddress && defaultAddress.addressLine1) {
    dispatch(
      setDeliveryAddress({
        address: convertMembersAddressToOloAddress(defaultAddress),
      }),
    );
  }
}

export function convertMembersAddressToOloAddress(address: MembersAddress): OloAddress {
  if (!address.addressLine1) {
    throw new Error('addressLine1 is a required field');
  }

  return {
    formatted: address.addressLine1,
    latLng: { lat: address.location?.latitude!, lng: address.location?.longitude! },
    comment: address.hint,
    street: address.street?.name,
    number: address.street?.number,
  };
}

export function convertToOloAddressMembersAddress(address: OloAddress): MembersAddress {
  const membersAddress: MembersAddress = {
    fullName: {
      firstName: '_',
      lastName: '_',
    },
    addressLine1: address.formatted,
    location: { latitude: address.latLng.lat, longitude: address.latLng.lng },
    hint: address.comment,
  };

  if (address.street || address.number) {
    membersAddress.street = {
      name: address.street || '',
      number: address.number || '',
    };
  }

  return membersAddress;
}
